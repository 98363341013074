/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/dropzone@5.1.1/dist/min/dropzone.min.css
 * - /npm/x-editable@1.5.1/dist/bootstrap3-editable/css/bootstrap-editable.min.css
 * - /npm/bootstrap-switch@3.3.4/dist/css/bootstrap3/bootstrap-switch.min.css
 * - /npm/bootstrap-select@1.13.18/dist/css/bootstrap-select.min.css
 * - /npm/selectize@0.12.4/dist/css/selectize.bootstrap3.min.css
 * - /npm/bootstrap-datepicker@1.9.0/dist/css/bootstrap-datepicker3.min.css
 * - /npm/bootstrap-tokenfield@0.12.0/dist/css/bootstrap-tokenfield.min.css
 * - /npm/ladda@1.0.4/dist/ladda-themeless.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
